.tthc-service-wrapper {
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.tthc-service-wrapper h4 {
  font-size: 30px;
  color: #1d2023;
  font-weight: 700;
}

/* @media (max-width:500px) {
  .tthc-service-wrapper h4 {
    font-size: 25px;
    color: #1d2023;
    font-weight: 700;
  } 
} */

.tthc-service-wrapper img {
  width: 100px;
  height: 100px;
  /* border-radius: 20px; */
  object-fit: contain;
}

.tthc-service-wrapper .boder-radius {
  border-radius: 50%;
}

/* ------------------------------------ */

.TTHCServicePhysiotherapy-wrapper {
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.TTHCServicePhysiotherapy-wrapper h4 {
  font-size: 30px;
  color: #1d2023;
  font-weight: bold;
}

.TTHCServicePhysiotherapy-wrapper img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.TTHCServicePhysiotherapy-wrappernew img{
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.TTHCServicePhysiotherapy-wrapper .boder-radius {
  border-radius: 50%;
}
