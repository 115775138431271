.image-text-container {
  position: relative;
  width: 100%;
}

.image-text-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8));
  z-index: 1;
}

.company-image {
  width: 100%;
  height: auto;
}

.text-overlay {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  z-index: 2;
  width: 90%;
}

.text-overlay p {
  margin-bottom: 10px;
}

@media (max-width: 930px) {
  .text-overlay {
    bottom: 15%;
    font-size: 14px;
  }
  .text2 {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .text-overlay {
    bottom:  6%;
    font-size: 10px;
  }
  .text2 {
    font-size: 10px !important;
  }
  .getquote {
    width: 100px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.OurVision {
  background-color: #f0f0f0 !important;
  padding: 30px;
}

.company .caregiver h4 {
  font-size: 30px;
  font-weight: 700;
}

.company .caregiver .card {
  padding: 10px;
  box-shadow: 0 0 11px rgba(162, 166, 173, 0.603);
  cursor: pointer;
  height: 250px;
}

.company .caregiver .card:hover {
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
}

.company .caregiver .hover-effect {
  /* color: black; */
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.company .caregiver p {
  color: black;
}

.company .caregiver .card .hover-effect:hover {
  color: var(--primaryColor);
}
.company .boxshadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}