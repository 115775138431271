.medical-staff-wrapper {
  padding: 20px 0px;
  background-color: #f9f9f9;
}

.medical-staff-wrapper h4 {
  font-size: 30px;
  font-weight: 700;
}

.medical-staff-slider {
  padding: 20px;
}

.medical-staff-slider img {
  width: 100%;
  height: 200px;
}

.medical-staff-slider .text-1 {
  font-weight: bold;
}

.medical-staff-slider .text-2 {
  color: var(--primaryColor);
  font-size: 15px;
}

.medical-staff-slider span {
  font-weight: bold;
}
.medical-staff-slider .slick-prev {
  left: -11px;
  z-index: 1;
}
.medical-staff-slider .slick-next {
  right: -11px;
  z-index: 1;
}
