.service-paln-wrapper {
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.ServicePlan-Tracheostomy-care-wrapper {
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.ServicePlan-Post-surgical-plan-wrapper{
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.ServicePlan-Post-emergency-care-wrapper{
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.ServicePlan-Post-emergency-care-wrapper{
  padding: 30px 0px;
  background-color: #f9f9f9;
}

.ServicePlan-Post-emergency-care-wrapper img{
 width: 64px;
 height: 64px;
}
