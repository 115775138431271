:root {
  --primaryColor: #1463f3;
  --lightgrayColor : #F0F0F0;
  --skyBackgroundColor : #d8e7ff;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}

.ff-outfit {
  font-family: "outfit" !important;
}

.ff-poppins {
  font-family: "poppins" !important;
}

.sky-background-color {
  background-color: #d8e7ff;
}
.object-fit-cover {
  object-fit: cover;
}
.text-justify {
  text-align: justify;
}
.primary-color {
  color: #1463f3;
}
.primary-bg-color{
  background-color: #1463f3;
}
/* .btn-primary{
  background-color: #1463f3;
} */
.lightgray-bg-color{
  background-color: #F0F0F0;
}
.lightgraydark-bg-color{
  background-color: #F9F9F9;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Outfit";
}


