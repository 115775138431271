.registerUser ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.registerUser  ul>li {
    width: 100%;
    font-size: 17px;
    height: 40px;
    border-right: none;
    border:none;
    background:#D8D8D8;
}
.registerUser ul > li.active {
    background: var(--primaryColor);
    position: relative;
    color: white;
}

.registerUser  ul>li:before,
.registerUser  ul>li:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0%;
}

.registerUser  ul>li:before {
    transform: translateX(1px);
    border: 20px solid transparent;
    border-left-color: white;
    z-index: 1;
}

.registerUser ul>li:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0%;
    border: 20px solid transparent; 
    border-left-color: var(--primaryColor);
    z-index: 2;
}

.registerUser ul>li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: var(--primaryColor);
    color: white;
    position: relative;
}

.registerUser ul>li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.registerUser ul > li:last-child.active .icon {
    background-color: white; 
    color: var(--primaryColor);
}

.registerUser ul > li.active a {
    color: white; 
}


.registerUser ul>li:last-child::after,
.registerUser ul>li:last-child::before {
    display: none;
}

.registerUser  ul>li>a {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.registerUser  ul>li>a,
.registerUser ul>li>a:hover,

.registerUser ul>li>a:visited {
    color: inherit;
    text-decoration: none;
}

@media (max-width:530px) {
    .details{
        font-size: 13px;
    }
    .icon{
        font-size: 18px !important;
    }
}
@media (max-width:390px) {
    .details > span{
        font-size: 12px !important;
    }
}

.registerUser .is-invalid{
    border: 1px solid red !important;
}