.benefits-rpo-wrapper{
    padding: 20px 0px;
    /* background-color: #f9f9f9; */
}

.benefits-rpo-wrapper .card-body img {
    width: 50px;
    height: 50px;
}

.benefits-rpo-wrapper .card-body h5 {
    font-size: 19px;
    font-weight: bold;
}

.benefits-rpo-wrapper .card{
    height: 100%;
    box-shadow: 0 0 5px rgba(26, 25, 25, 0.733);
}

/* ---------------- key-benefit ------------------- */

.key-benefit-wrapper{
    background-color: #F0F0F0;
    padding: 40px 0px;
}

.key-benefit-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.key-benefit-wrapper h4{
    font-size: 30px;
    font-weight: bold;
}

.key-benefit-wrapper h5{
    font-weight: 600;
    font-size: 18px;
}

