/* .nursingSerivec .slick-prev:before,
.nursingSerivec .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.nursingSerivec svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
    background-color: #1D2023;
    color: white;
    padding: 10px;
    border-radius: 50%;
}

.nursingSerivec .slick-next {
    right: 0px;
}

.nursingSerivec .slick-prev {
    left: 0px;
    z-index: 1;
}

/* .nursingSerivec .slick-dots .nursingSerivec li button:before {
    display: none !important;
} */ 

/* Adjust card layout for both desktop and mobile */
.nursingService .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

/* Ensure the button is properly aligned at the bottom */
.nursingService .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;
}

.nursingService .card-text {
    margin-bottom: 5px;
}

.nursingService button {
    margin-top: auto;
}

/* Slick carousel navigation button styles */
.nursingService .slick-prev:before,
.nursingService .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    opacity: .75;
    color: black;
}

.nursingService .slick-next,
.nursingService .slick-prev {
    top: 50%;
    transform: translateY(-50%);
}

.nursingService .slick-next {
    right: 1px;
}

.nursingService .slick-prev {
    left: 1px;
    z-index: 1;
}

.nursingService svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
    overflow: visible;
    background-color: #1D2023;
    color: white;
    padding: 10px;
    border-radius: 50%;
}

/* Responsive adjustments for mobile view */
@media (max-width: 698px) {
    .nursingService .slick-slide {
        padding: 0 10px;
    }

    .nursingService .card {
        width: 100%;
        height: auto;
    }

    .nursingService .card-img-top {
        height: 120px;
    }

    .nursingService button {
        margin-top: 10px;
    }
}

.nursingService  h4 {
    font-size: 30px;
    font-weight: bold;
  }

  .nursingService{
    padding: 30px 0px;
  }