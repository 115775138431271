.expertise-facility-management-wrapper {
    padding: 40px 0px;
    background-color: #F0F0F0;
}

.expertise-facility-management-wrapper img{
    width: 50px;
    height: 50px;
}

.expertise-facility-management-wrapper h5{
    font-weight: bold;
}

.expertise-facility-management-wrapper .border-shadow{
    box-shadow: 0 0 5px #ccc;
}