
.footer{
    background-position: bottom;
}
.relative-position {
    position: relative;
    width: 100%;
}

.relative-position input {
    padding-right: 50px; 
}

.relative-position button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}