.travel-nurse-cost-wrapper{
    padding: 30px 0px;
    /* background-color: #f9f9f9; */
}

.travel-nurse-cost-wrapper h4{
    font-size: 44px;
    font-weight: bold;
}

.travel-nurse-cost-wrapper .shadow-1{
    box-shadow: 0 2px 5px -1px rgba(7, 5, 5, 0.616);
}

@media (max-width:500px) {
    .travel-nurse-cost-wrapper h4{
        font-size: 30px;
        font-weight: bold;
    }
}