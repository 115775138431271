input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
.file-upload-container {
    margin-top: 10px;
}
.registerVendors ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.registerVendors  ul>li:last-child {
    background:#EBEBEB;
}

.registerVendors  ul>li {
    width: 100%;
    font-size: 17px;
    height: 40px;
    border-right: none;
    border:none;
    background:#D8D8D8;  
}

.registerVendors ul > li.active {
    background: var(--primaryColor);
    position: relative;
    color: white;
}

.registerVendors  ul>li:before,
.registerVendors  ul>li:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0%;
}
.registerVendors ul>li:first-child::before{
    transform: translateX(1px);
    border: 20px solid transparent;
    border-left-color: white;
    z-index: 1;
}
.registerVendors ul>li:first-child::after{
    content: "";
    position: absolute;
    left: 100%;
    top: 0%;
    border: 20px solid transparent; 
    border-left-color: var(--primaryColor);
    z-index: 2;
}


.registerVendors ul > li:nth-child(2)::before {
    transform: translateX(1px);
    border: 20px solid transparent;
    border-left-color: white;
    z-index: 1;

}
.registerVendors ul >li:nth-child(2).active::after{
    content: "";
    position: absolute;
    left: 100%;
    top: 0%;
    border: 20px solid transparent; 
    border-left-color: var(--primaryColor);
    z-index: 2;
}

.registerVendors ul >li:nth-child(2)::after{
    content: "";
    position: absolute;
    left: 100%;
    top: 0%;
    border: 20px solid transparent; 
    border-left-color: #d8d8d8;
    z-index: 2;
}
.registerVendors ul >li:nth-child(2) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
}

.registerVendors ul>li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: var(--primaryColor);
    color: white;
    position: relative;
}

.registerVendors ul>li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.registerVendors ul > li:last-child.active .icon {
    background-color: white; 
    color: var(--primaryColor);
}

.registerVendors ul > li.active a {
    color: white;
}
.registerVendors ul >li:first-child .icon {
    color: white;
    border: none !important;
    background-color: white;
    color: var(--primaryColor);
}
.registerVendors ul > li.active .icon {
    color: white;
    border: none !important;
    background-color: white;
    color: var(--primaryColor);
}


.registerVendors ul>li:last-child::after,
.registerVendors ul>li:last-child::before {
    display: none;
}

.registerVendors  ul>li>a {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.registerVendors  ul>li>a,
.registerVendors ul>li>a:hover,

.registerVendors ul>li>a:visited {
    color: inherit;
    text-decoration: none;
}


@media (max-width:420px) {
    .registerVendors  ul>li>a {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }
}

@media (max-width:345px) {
    .registerVendors  ul>li>a {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }
    .vendors{
        padding: 10px !important;
    }
   
}

@media (max-width:420px) {
    .registerVendors ul>li:first-child::after{
        content: "" !important;
        position: absolute !important;
        left: 99% !important;
        top: 0% !important;
        border: 20px solid transparent !important;
        border-left-color: var(--primaryColor) !important;
        z-index: 2 !important; 
    }
    .registerVendors ul >li:nth-child(2).active::after{
        content: "" !important;
        position: absolute !important;
        left: 99% !important;
        top: 0% !important;
        border: 20px solid transparent !important; 
        border-left-color: var(--primaryColor) !important;
        z-index: 2 !important;
    }
}

.registerVendors .is-invalid{
    border: 1px solid red !important;
}