/* #main_cont div div i {
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: clamp(70px, 6vw, 200px);
} */


.table>:not(caption)>*>* {
  background-color: #F8F8F8;
}

tr {
  width: 2px !important;
}

@media (max-width:600px) {

  .table tbody tr td,
  .table tbody tr th {
    font-size: 11px;
  }
}

.table tbody tr td,
.table tbody tr th {
  line-height: normal;
}