.equipment-all-type-wrapper {
    background-color: #f9f9f9;
    padding: 30px 0px;
}

.equipment-all-type-wrapper h4{
    font-size: 30px;
    font-weight: bold;
}
.equipment-all-type-wrapper .img-wrap{
    background-color: #D8E7FF;
}

.equipment-all-type-wrapper .img-wrap img{
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.equipment-all-type-wrapper .card-body{
    text-align: center;
}

.equipment-all-type-wrapper .card-body h5{
    font-size: 14px;
    font-weight: 600;
}